<template>
    <div v-frag>
        <div class="container-fluid">
            <div class="row" style="padding-bottom:60px">
                <div class="col-md-2 hidden-sm hidden-xs"></div>
                <div class="col-md-8">
                    <h3>{{ $t('header.messages') }}</h3>
                    <Loader v-if="loading"/>
                    <template v-if="!loading">
                        <div class="msg" v-for="(mg,i) in  msg" :key="`msg${i}`">
                            <div class="msg-body" :class="{'txt-bold': !mg.status}" @click="readMessage(i)">{{ (mg.status) ? mg.message : mg.message.substring(0, 30) + '...' }}</div>
                            <div class="msg-header">{{ mg.createdAt | moment('D/MM,HH:mm') }}</div>
                        </div>
                        <intersect sentinal-name="fetch-more" @on-intersection-element="onIntersectionElement"/>
                        <div class="text-center pd-top" v-if="msg.length == 0">
                            <Alerts type="alert-info" :message="$t('header.no-messages')" />
                        </div>
                    </template>
                </div>
                <div class="col-md-2 hidden-sm hidden-xs"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Auth from "@/services/auth";
import Alerts from "@/components/sections/betslip-sections/alert";
import Loader from "@/components/sections/loader.vue";
import { mapGetters } from "vuex";
import intersect from './../sections/feeds/intersect.vue';

export default {
    name: 'Messages',
    components:{
        Alerts,
        Loader,
        intersect,
    },
    data: () =>({
        messages: [],
        loading:false
    }),
    computed: {
        ...mapGetters({
            msg: "messages",
            meta: 'meta',
        })
    },
    methods: {
        readMessage: function(i) {
            this.msg.map( (m,x)=> {
                if(i == x && !m.status) {
                    m.status = true;
                    Auth.getSingleMessage(m.id)
                    .then( res => console.log(res))
                    .catch( err => console.log(err))
                }
            } )
        },
        loadMessages: async function() {
            this.loading = true;
        
            await this.$store.dispatch("set_messages",{
                post:{
                    "page":0,
                    "size":15
                },
                type:'new'
            });

            this.loading = false;
        },
        onIntersectionElement:function(value) {
            // value will be true when the component is visible on the page otherwise false
            // you can do whatever you want
            if(value) {
                this.getNext();
            }
        },
        getNext: async function() {
            if(this.msg.length > 14) {
                await this.$store.dispatch("set_messages",{
                    post:{
                    "page":this.meta.page,
                    "size":15
                    },
                    type:'paged'
                });
            }
        }
    },
    async mounted() {
        this.loadMessages()
    }
}
</script>
<style scoped>
.msg {
    padding: 15px 10px;
    background-color: var(--bg-dark-medium);
    border-radius: 5px;
    margin-bottom: 5px;
    display:flex;
    justify-content: space-between;
    cursor:pointer;
}
.txt-bold {
    font-weight: 500;
    color: var(--bg-orange);
}

</style>